<template>
  <div class="home">
    <!-- 图片轮播开始 -->
    <div class="swiper topCarousel">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="@/assets/images/bkg2.jpg" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/images/bkg3.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/images/1627458510.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/images/1627458715.png" />
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-white"></div>
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>
    </div>
    <!-- 图片轮播结束 -->
    <!--解决方案开始-->
    <div class="container solution" >
      <h2>解决方案</h2>
      <p>为企业提供一系列的解决方案</p>
      <div class="swiper solutionCarousel" v-if="solutionList.length>0">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in solutionList" :key="index">
            <div class="team-pic">
              <router-link :to="{ path: '/solution?solu_type=' + item.soluTypeId }" :title="item.soluTypeName">
              <img :src="StaticResDomain + item.soluTypeCover" v-if="item.soluTypeCover != null"/>
                <img src="@/assets/images/solution1.png" v-else/>
              </router-link>
            </div>
            <div class="team-content">
              <router-link :to="{ path: '/solution?solu_type=' + item.soluTypeId }" :title="item.soluTypeName" class="team-title">
                <h3>{{ item.soluTypeTitleLv1 }}</h3>
                <p>{{ item.soluTypeTitleLv2 }}</p>
              </router-link>
              <p></p>
              <p>{{ item.soluTypeIntro }}</p>
              <p></p>
              <a
                href="#/solution?solu_type=2"
                title="其他行业解决方案"
                target="_self"
                class="team-more"
                >MORE</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--解决方案结束-->
    <!-- 公司简介 -->
    <div class="company-profile">
      <div class="container">
        <div class="row">
          <div class="col-md-4 compro-cont">
            <router-link to="/about?about_type=1" title="公司介绍">
              <img src="@/assets/images/compro1.png" />
              <h4>公司介绍</h4>
              <p>
                面向社会各行业提供定制信息化服务，提供优质的信息化解决方案。
              </p>
            </router-link>
          </div>
          <div class="col-md-4 compro-cont">
            <router-link to="/about?about_type=3" title="资质荣誉">
              <img src="@/assets/images/compro2.png" />
              <h4>资质荣誉</h4>
              <p>以技术为保证，以满足客户需求为中心。</p>
            </router-link>
          </div>
          <div class="col-md-4 compro-cont">
            <router-link to="/about?about_type=2" title="服务宗旨">
              <img src="@/assets/images/compro3.png" />
              <h4>服务宗旨</h4>
              <p>
                不断跟踪客户的服务需求，为客户提供高质量、高效率的服务，并保持持久的合作，赢得客户信任，确保客户成功，和客户共同发展。
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <!-- 新闻中心 -->
    <div class="container news" v-if="newsList">
      <h2>新闻中心</h2>
      <p class="desc">为企业提供一系列软件相关的配套服务</p>
      <ul class="news-list row">
        <li v-for="(news, index) in newsList" :key="index" class="col-md-6">
          <div>
            <h4>
              <router-link
                target="_blank"
                :to="{ path: 'newsdetails', query: { id: news.newsId } }"
                >{{ news.newsTitle }}</router-link
              >
            </h4>
            <p>
              {{ news.newsContent | filtersText }}
            </p>
            <p class="info">
              <span>{{ news.createTime | filtersData }}</span>
              <b-icon icon="eye-fill" width="18" height="18"></b-icon>
              <span>{{ news.hits }}</span>
            </p>
          </div>
        </li>
      </ul>
      <router-link to="/news" class="more">查看更多</router-link>
    </div>
    <!-- 新闻中心 -->
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay]);

export default {
  name: "Home",
  data() {
    return {
      newsList: null,
      solutionList: [],
    };
  },
  mounted() {
    var topCarousel = new Swiper(".topCarousel", {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });



    var _this = this;
    //获取首页新闻信息
    _this.getNews()
    //获取解决方案信息
    _this.getSolution()
  },
  methods: {
    //获取新闻数据
    getNews() {
      var _this = this;
      var params = {};
      params["pageNum"] = 1;
      params["pageSize"] = 6;
      //ajax获取新闻列表信息
      _this.$axios
        .get("/system/websitenews/websiteHomeList", { params: params })
        .then(function (response) {
          if (response.data.code == 200) {
            _this.newsList = response.data.data.rows;
          }
        });
    },
    //获取解决方案
    getSolution() {
      var _this = this;
      var params = {};
      params["soluTypeStatus"] = 0;
      params["soluTypeIndex"] = 0;
      _this.$axios
        .get("/system/solutiontype/websiteList", { params: params })
        .then(function (response) {
          if (response.data.code == 200) {
            _this.solutionList = response.data.rows;
          }
        });
    },
  },
  filters: {
    //提纯富文本中的文字并显示前150的字符
    filtersText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1, "");
        return msg.substr(0, 100)+"...";
      } else {
        return "";
      }
    },
    filtersData(val) {
      if (val != null && val != "") {
        return val.substr(0, 10);
      } else {
        return "";
      }
    },
  },
  watch:{
    solutionList(nVal){
      if(nVal.length>0){
        this.$nextTick(()=>{
          var solutionSwiper = new Swiper(".solutionCarousel", {
          slidesPerView: 3,
          spaceBetween: 30,
          loopAdditionalSlides: 3,
          centeredSlides: true,
          loop: true,
          autoplay: true,
          observer: true,
          observeParents: true,
        });
        })

      }
    }
  }
};
</script>
<style>
.topCarousel {
  width: 100%;
  height: 550px;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #ffffff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}

.swiper-slide-active {
  transform: scale(1);
  z-index: 1;
}

.home {
  background-color: #f6f4f7;
  color: #999999;
}

.solution {
  padding: 40px 0px;
}

.solution h2 {
  text-align: center;
  color: #00c9a1;
}

.solution p {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.solutionCarousel {
  height: 450px;
}

.solutionCarousel .swiper-slide {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  display: block !important;
}

.swiper-slide .team-pic img {
  width: 100%;
  height: 245px;
}

.swiper-slide .team-content {
  clear: both;
  width: 100%;
  margin: 30px 0px;
}

.swiper-slide .team-content a {
  text-decoration: none;
}

.swiper-slide .team-content .team-title {
  line-height: 30px;
}

.swiper-slide .team-content .team-title h3 {
  color: #00c9a1;
  font-size: 24px;
}

.swiper-slide .team-content .team-title p {
  color: #a6cfc8;
  font-size: 16px;
}

.swiper-slide .team-content p {
  color: #999999;
  font-size: 14px;
  line-height: 26px;
}

.swiper-slide .team-content .team-more {
  color: #63857f;
  font-size: 15px;
  transform: color 0.25s;
}

.swiper-slide h3 {
  width: 100%;
}

/* 公司简介 */
.company-profile {
  width: 100%;
  background-color: #ffffff;
  margin: 30px 0px;
}

.company-profile a {
  color: #999999;
}

.company-profile a:hover {
  text-decoration: none;
  color: #999999;
}

.compro-cont {
  display: block;
  margin: 30px 0px;
  text-align: center;
}

.compro-cont h4 {
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 46px;
  border-bottom: 1px solid #e1e1e1;
}

.compro-cont h4:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 1px;
  background-color: #555555;
  margin-top: 46px;
  transition: width 1s;
  -webkit-transition: width 1s;
}

.compro-cont h4:hover {
  cursor: pointer;
  color: #00c9a1;
}

.compro-cont h4:hover::before {
  background-color: #00c9a1;
  width: 93%;
}

.compro-cont p {
  text-align: left;
}

/* 公司简介 */
/* 新闻中心 */
.news {
  padding: 60px 0px;
}

.news h2 {
  font-size: 36px;
  line-height: 45px;
  font-weight: 300;
  color: #555555;
  text-align: center;
}

.news .desc {
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  color: #999999;
}

.news .more {
  display: block;
  width: 150px;
  color: #555555;
  margin: 0px auto;
  border-radius: 5px;
  border: 1px solid;
  text-align: center;
  line-height: 35px;
}

.news .more:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #00c9a1;
}

.news-list {
  padding-left: 0px;
  margin: 30px 0px;
}

.news-list li {
  width: 50%;
  padding: 15px;
  float: left;
}

.news-list li:hover {
  box-shadow: 3px 3px 3px 3px #cccccc;
}

.news-list li a {
  color: #555555;
  font-size: 1.286rem;
}

.news-list li a:hover {
  color: #00c9a1;
  text-decoration: none;
}

.news-list li p {
  color: #999999;
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 5px;
}

.news-list li p span {
  margin-right: 10px;
}

.news-list li svg {
  margin-right: 10px;
}

/* 新闻中心 */
</style>
